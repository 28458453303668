import React, { Suspense, useState } from 'react';
import './App.css';
import { Canvas, useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls, Sky, Cloud } from '@react-three/drei';
import { Leva, useControls } from 'leva';
import Cube from './components/Cube';
import About from './components/About'
import Projects from './components/Projects'
import Contact from './components/Contact'
// import House from './components/House'
import House from './assets/casa.gltf'
import Sign from './components/Sign'
import Land from './components/Land'
import House2 from './components/House2'

//position={[-5, 0, 2]}
function MyHouse() {
  const gltf = useLoader(GLTFLoader, House)
  return <primitive object={gltf.scene} position={[-5, 0, 2]} />
}

export default function App() {
  const [about, setAbout] = useState(false);
  const [projects, setProjects] = useState(false);
  const [contact, setContact] = useState(false);
  return (
    <>
    <Suspense fallback={<div>Loading...</div>}>
      <Leva collapsed/>
      <Canvas id="canvas" shadows >
        <OrbitControls minDistance={0.3} maxDistance={0.3} dampingFactor={0.07} rotateSpeed={0.5} />
        {/* <DeviceOrientationControls /> */}
        <ambientLight intensity={0.7}/>
        <pointLight castShadow intensity={0.33} position={[11, 50, 3]} color={"#ffffff"} shadowMapWidth="5120" shadowMapHeight="5120"/>
        <Sky elevation={1} azimuth={0.57}/>
        {/* <Cloud opacity={0.5} speed={0.4} width={10} depth={1.5} segments={20} position={[0, 0, 0]}/> */}
        {/* <House /> */}
        {/* <House2 /> */}
        <Sign />
        {/* <Land /> */}
        <Cube position={[-4.5, -1.1, -1.2]} active={about} setActive={setAbout}/>
        <Cube position={[-3, -1, 6]} active={projects} setActive={setProjects}/>
        <Cube position={[0.6, 3.1, 5.3]} active={contact} setActive={setContact}/>
        <Suspense fallback={null}><MyHouse /></Suspense>
      </Canvas>
      <About active={about} setActive={setAbout} />
      <Projects active={projects} setActive={setProjects} />
      <Contact active={contact} setActive={setContact} />
    </Suspense>
    </>
  );
}
