import React from 'react';
import { useLoader } from '@react-three/fiber';
import { MeshDistortMaterial } from '@react-three/drei';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
// import { TextureLoader } from 'three';
import woodImg from '../assets/wood.jpg';

function SignMain(){
  const texture = useLoader(TextureLoader, woodImg)
  return (
    <mesh castShadow receiveShadow position={[2, 0, -10]} rotation={[0, 0, 0]}>
      <boxBufferGeometry attach="geometry" args={[4 ,3, 0.75]} />
      {/* <meshStandardMaterial map={texture} attach="material" flatShading="true"/> */}
      <MeshDistortMaterial map={texture} attach="material" distort={0.25} speed={5} />
    </mesh>
  )
}

function SignPost(){
  const texture = useLoader(TextureLoader, woodImg)
  return (
    <mesh castShadow receiveShadow position={[2, -3, -10]} rotation={[0, 0, 0]}>
      <boxBufferGeometry attach="geometry" args={[0.5 ,3, 0.5]} />
      <meshStandardMaterial attach="material" map={texture} flatShading="true"/>
    </mesh>
  )
}

export function Sign(props) {
  return (
    <>
    <SignMain />
    <SignPost />
    </>
  )
}

export default Sign;