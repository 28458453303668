import React, { useState, useEffect } from 'react';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import woodImg from '../assets/wood.jpg';
import { useSpring, animated, config } from '@react-spring/three'

export default function Cube(props) {
  const texture = useLoader(TextureLoader, woodImg)

  const [hovered, sethovered] = useState(false)
  // const [active, setActive] = useState(false);

  const { scale } = useSpring({ scale: props.active ? 1.1 : 1, config: config.wobbly })
  const { color } = useSpring({ color: hovered ? "#ffffff" : "#dbdbdb" })

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
  }, [hovered])
  return (
    <animated.mesh
      receiveShadow
      castShadow
      position={props.position}
      scale={scale}
      onClick={() => props.setActive(!props.active)}
      onPointerOver={() => sethovered(true)}
      onPointerOut={() => sethovered(false)}
    >
      <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
      <animated.meshStandardMaterial map={texture} attach="material" color={color} />
    </animated.mesh>
  )
}
