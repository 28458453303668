import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import model from '../assets/rock_large.gltf'

export default function Model(props) {
  const group = useRef()
  const { nodes } = useGLTF(model)
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.caillou1.geometry} position={[0, -5, -5]} scale={[12, 1.5, 15]}>
        <meshStandardMaterial attach="material" color={'tan'} />
      </mesh>
    </group>
  )
}

useGLTF.preload(model)