import React from 'react';
import { DialogTitle, Dialog, DialogContent, DialogContentText, IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close'

export default function About(props) {
  // const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    props.setActive(!props.active);
    setScroll(scrollType);
  };

  const handleClose = () => {
    props.setActive(!props.active);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (props.active) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.active]);

  return (
    <div>
      {/* <Button sx={{ position: 'absolute', top: 0, left: 0 }} onClick={handleClickOpen('paper')}>About</Button> */}
      {/* <Button onClick={handleClickOpen('body')}>scroll=body</Button> */}
      <Dialog
        open={props.active}
        fullWidth={true}
        maxWidth={'md'}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">About
        <IconButton onClick={handleClickOpen('paper')} sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}>
          <Close />
          </IconButton>
          </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            My name is Kevin and I like to make things.
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}